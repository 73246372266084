.normal-tabs {
  .MuiTabs-scroller {
    .MuiTabs-indicator {
      // background-color: black !important;
      background: linear-gradient(
        to right,
        transparent,
        transparent 10%,
        black 10%,
        black 90%,
        transparent 90%,
        transparent
      ) !important;
    }
  }
}

.black-tabs {
  .MuiTabs-scroller {
    .MuiTabs-indicator {
      //background-color: black !important;
      background: linear-gradient(
        to right,
        transparent,
        transparent 10%,
        white 10%,
        white 90%,
        transparent 90%,
        transparent
      ) !important;
    }
  }
}

div[aria-label='lot tabs'][role='tablist'] {
  .MuiTab-root {
    padding-left: 36px;
    padding-right: 36px;
  }

  button {
    color: #666666;
    background: transparent !important;
  }

  button.Mui-selected {
    color: black;
    background: #f2f2f2 !important;
  }
}

div[aria-label='side tabs'][role='tablist'] {
  .MuiTab-root {
    padding: 16px;
  }

  button {
    color: #ffffff !important;
    background: #333333 !important;
  }

  button.Mui-selected {
    background: #ffffff !important;
  }
}

div[aria-label='gamification tabs'][role='tablist'] {
  background: #151515 !important;
  .MuiTab-root {
    padding: 16px;
    opacity: 1 !important;
  }

  button {
    color: #919191 !important;
    background: #151515 !important;
  }

  button.Mui-selected {
    background: #151515 !important;
  }
}

div[aria-label='neutral tabs'][role='tablist'] {
  .MuiTab-root {
    padding: 16px;
  }
}

div[aria-label='noti tabs'][role='tablist'] {
  .MuiTab-root {
    margin-left: 14px;
    z-index: 10000;
  }
}

div[aria-label='bottom tabs'][role='tablist'] {
  .MuiTab-root {
    padding: 16px;
  }
  button.Mui-selected {
    background: #f1f1f1 !important;
  }
}
