.navbarSep {
  border-bottom: 1px solid rgb(0, 0, 0);
}

img {
  height: 56px;
  width: auto;
}

a.navLink {
  margin-right: 36px;
  @at-root .navbar--admin & {
    @media (max-width: 1140px) {
      margin-right: 24px;
    }
  }

  &.navLink--userMenuIcon {
    margin-left: -17px;
    // margin-right: 32px - 16px;
    margin-right: 56px - 16px;
  }

  &.navLink--home {
    button {
      margin-right: -12px;
      margin-left: -12px;
    }
  }

  //Regular Menu Items
  &:not(.navLink--home) {
    margin-left: 8px;
    &.navLink--name {
      margin-bottom: 0;
    }
  }

  &:hover {
    cursor: pointer;
  }

  &:hover,
  &:active {
    text-decoration: none;
  }

  &.active {
    .audiSpan {
      color: black;
      border-bottom: 2px solid black;
      padding-bottom: 4px;
    }
    .lightSpan {
      color: #000000;
      font-size: 22px;
      font-weight: bold !important;
    }
    .darkSpan {
      color: #ffffff;
      font-size: 22px;
      font-weight: bold !important;
    }
  }

  .audiSpan {
    color: #666666;
  }
  .lightSpan {
    color: #666666;
    font-size: 22px;
  }
  .darkSpan {
    color: #838383;
    font-size: 22px;
  }
}

#menu-appbar {
  z-index: 1000 !important;
}
