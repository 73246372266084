a.footerButton {
  &:hover {
    cursor: pointer;
  }
  &:hover,
  &:active {
    text-decoration: none;
  }
  span {
    color: #333;
  }
}

.white-text {
  color: white !important;
}
