.player-wrapper {
  width: 100%;
  padding-top: 56.25%;
  position: relative;

  /*Seekbar: RANGE INPUT*/
  /*Remove all Input Range Styles*/
  input[type='range'] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  @supports (-moz-appearance: none) {
    input[type='range'] {
      top: 2px;
      left: -2px;
    }
  }

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type='range']::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /*STYLE THE THUMB*/
  /* Special styling for WebKit/Blink */
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 18px;
    width: 18px;
    border-radius: 10px;
    margin-top: -9px;
    background: #333333;
    border: 1px solid white;
    cursor: pointer;
    margin-left: -2px;
  }

  /* All the same stuff for Firefox */
  input[type='range']::-moz-range-thumb {
    height: 18px;
    width: 18px;
    border-radius: 10px;
    margin-top: -9px;
    background: #333333;
    border: 1px solid white;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  input[type='range']::-ms-thumb {
    height: 18px;
    width: 18px;
    border-radius: 10px;
    margin-top: -9px;
    background: #333333;
    border: 1px solid white;
    margin-left: -2px !important;
  }

  /*STYLE THE TRACK*/
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
  }

  input[type='range']:focus::-webkit-slider-runnable-track {
  }

  input[type='range']::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border: none;
  }

  input[type='range']::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-color: transparent;
    color: transparent;
  }

  input[type='range']::-ms-fill-lower {
  }

  input[type='range']:focus::-ms-fill-lower {
  }

  input[type='range']::-ms-fill-upper {
  }

  input[type='range']:focus::-ms-fill-upper {
  }
}

.react-player,
.quiz,
.survey {
  margin-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.quiz {
  z-index: 10;
  background: rgba(0, 0, 0, 0.6);
}

.black-checkbox {
  input {
    border-color: white !important;
  }
  input::before {
    content: url(data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6%2012.2l4.3%204.2%208.1-10.3%22%20stroke%3D%22%23FFFFFF%22%20fill%3D%22none%22%20%2F%3E%0A%20%20%20%20%20%3C%2Fsvg%3E);
  }
  label {
    span {
      display: none !important;
    }
  }
}

.red-checkbox {
  input {
    border-color: #f50537 !important;
  }
  input::before {
    content: url(data:image/svg+xml;utf8,%3Csvg%20width%3D%2224%22%20height%3D%2224%22%20viewBox%3D%220%200%2024%2024%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%20%20%3Cpath%20d%3D%22M6%2012.2l4.3%204.2%208.1-10.3%22%20stroke%3D%22%23FFFFFF%22%20fill%3D%22none%22%20%2F%3E%0A%20%20%20%20%20%3C%2Fsvg%3E);
  }
  label {
    span {
      display: none !important;
    }
  }
}
