.overlay {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.35);

  button,
  svg,
  .liveCountdown {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 3rem;
  }
  .liveNow {
    position: absolute;
    width: 80%;
    padding-bottom: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  .over {
    position: absolute;
    width: 80%;
    padding-bottom: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  .liveCountdown {
    text-align: center;
    width: 90%;
    h2,
    p {
      color: white;
    }
    .cd-wrapper {
      padding-top: 16px;
      display: flex;
      justify-content: space-evenly;
    }
  }
}
