/*//light Checkbook*/
.lightCheckbookWrapper input {
  background: #ffffff;
  border-color: #d5d5d5;
}
.lightCheckbookWrapper span {
  color: #ffffff;
  font-family: inherit;
}

/*//dark Checkbook*/
.darkCheckbookWrapper input {
  background: #272727;
  border-color: #343434;
}
.darkCheckbookWrapper input:hover {
  border-color: #575757 !important;
}
.darkCheckbookWrapper input[type='checkbox']:checked:before {
  filter: invert(1);
}
.darkCheckbookWrapper div label span {
  color: #ffffff;
  font-family: inherit;
}

/* Dark radio button */

.darkRadioBtn input[type='radio']:checked:before {
  background-color: #ffffff !important;
}
.darkRadioBtn input[type='radio']:before {
  background-color: #000000 !important;
}

/* light Faqs questions wrapper */
.lightQuestionsWrapper {
  background: #efefef;
}
.lightQuestionsWrapper span {
  font-family: inherit;
}
.lightQuestionsWrapper svg {
  color: #767676;
}
.lightQuestionsWrapper button {
  border: solid #d7d7d7;
  border-width: 1px 0;
}
.lightQuestionsWrapper div {
  background: #efefef;
}
.lightQuestionsWrapper div div div {
  background: #e3e3e3;
  font-family: inherit;
}

/* //Dark Faqs questions wrapper */
.darkQuestionsWrapper {
  background: #151515;
}
.darkQuestionsWrapper span {
  color: #efefef;
  font-family: inherit;
}
.darkQuestionsWrapper svg {
  color: #efefef;
}
.darkQuestionsWrapper button {
  border: solid #333333;
  border-width: 1px 0;
}
.darkQuestionsWrapper div {
  background: #151515;
}
.darkQuestionsWrapper div div div {
  background: #222222;
  font-family: inherit;
}

/*//dark Checkbook survey*/
.darkCheckbookWrapperSurvey label {
  color: #ffffff !important;
}
.darkCheckbookWrapperSurvey input:hover {
  border-color: #ffffff !important;
}
.darkCheckbookWrapperSurvey input[type='checkbox']:checked:before {
  filter: invert(1);
}
.darkCheckbookWrapperSurvey input:checked {
  border-color: #ffffff !important;
}
