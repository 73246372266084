.black-form,
.black-form:hover {
  label {
    color: white !important;
  }
  input {
    color: white;
  }
  div::after {
    border-bottom-color: white !important;
  }
}

.lot-textarea,
.lot-textarea:hover {
  label {
    color: white !important;
  }
  textarea {
    color: white !important;
  }
  div {
    border-color: white !important;
  }
  div::after {
    border-bottom-color: white !important;
  }
}

// survey text area light
.survey-textarea-light,
.survey-textarea-light:hover {
  label {
    color: #767676 !important;
  }
  textarea {
    color: #4b4949 !important;
  }
  div {
    border-color: #d5d5d5 !important;
    background-color: #ffffff !important;
  }
  div::after {
    border-bottom-color: #d5d5d5 !important;
  }
}

// survey text area dark
.survey-textarea-dark,
.survey-textarea-dark:hover {
  label {
    color: #919191 !important;
  }
  textarea {
    color: white !important;
  }
  div {
    border-color: #343434 !important;
    background-color: #272727 !important;
  }
  div::after {
    border-bottom-color: #343434 !important;
  }
}
