body {
  overflow-x: hidden;
  background-color: white !important;
  scrollbar-color: black #f1f1f1;
  scrollbar-width: 100px;
  --ck-z-modal: 10000;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

//.u-buttonWithYMargin {
//  marginTop: 12px !important;
//  marginBottom: 12px !important;
//}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

button {
  margin: 12px 0;
}

time {
  font-weight: normal;
  font-family: 'AudiType', sans-serif;
}

.hide-after,
.hide-after:hover {
  div {
    padding-right: 50px !important;
  }
  div::after {
    display: none !important;
  }
}

label {
  & > span:only-of-type {
    display: none !important;
  }
}

table {
  border-collapse: collapse;
}

.field-error {
  color: #f50537;
}

::cue {
  font-size: 20px;
  font-family: 'AudiType', sans-serif;
}

.fade-in {
  animation: fadeIn ease 10s;
  -webkit-animation: fadeIn ease 10s;
  -moz-animation: fadeIn ease 10s;
  -o-animation: fadeIn ease 10s;
  -ms-animation: fadeIn ease 10s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  img {
    height: 100%;
    width: 100%;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sessionTable .MuiTableCell-root {
  border-bottom: 0px !important;
}

.editorWrapper {
  display: flex;
  flex-direction: column-reverse;
  max-height: 400px;
}

.ck.ck-editor__editable_inline {
  border: 1px solid #c4c4c4 !important;
}

#scheduleDesc p {
  font-size: 10px;
  line-height: 16px;
}

#lotDescFE p {
  color: white !important;
}

#lightTooltip p {
  color: white !important;
}
